<template>
  <div class="ask-leave-switch-config-p">
    <fm-title title-text="假条审批流程管理" :title-menus="[{key: 'add', label: '新增'}].filter(v => $authFunsProxy[v.key])" @clickTitleMenu="chooseData = null;openDialog = true">
    </fm-title>
    <div class="c-content">
      <fm-table-new
        :columns="columns"
        :auto-height="true"
        :data-list="showTypeList"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions.filter(v => $authFunsProxy[v.key])" :row="row"></table-actions>
      </fm-table-new>
    </div>
    <fm-form-dialog
      form-title="假条审批流程"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-modal v-model="modal" width="600px" v-if="modal">
      <div v-loading="loading">
      <fm-title :title-text="chooseData.label + ' 审批流程'" :title-menus="[{key: 'add', label: '添加'}]" @clickTitleMenu="openDialogFlow = true">
      </fm-title>
      <div style="width: 100%;height: 50vh">
        <fm-table-new
          :columns="flowColumns"
          :auto-height="true"
          :data-list="flowList"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableActionFlow" slot-scope="{ row }" :data="tableActionsFlow" :row="row"></table-actions>
        </fm-table-new>
      </div>
      <div class="f-b">
        <fm-btn style="margin-right: 30px;" @click="save">保存</fm-btn>
        <fm-btn @click="modal = false">取消</fm-btn>
      </div>
      </div>
    </fm-modal>
    <fm-form-dialog
      form-title="新增步骤"
      :open-dialog.sync="openDialogFlow"
      :form-parms="formParmsFlow"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmitFlow"
      @handleClose="openDialogFlow = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  roleRequest
} from '@/api'

import {
  statusManageRequest,
  askLeaveApplyRequest
} from '../../api'

import TableActions from '@/components/base/TableActions'

export default {
  components: {
    TableActions
  },
  data () {
    return {
      loading: false,
      openDialogFlow: false,
      typeList: [],
      chooseData: {},
      modal: false,
      openDialog: false,
      statusGroup: null,
      statusList: [],
      switchList: [],
      personnelRoleId: null,
      dataSourceKeys: [],
      userSourceKeys: [],
      flowList: [],
      tableActions: [{key: 'flow', label: '流程'}, {key: 'edit', label: '修改'}, {key: 'del', label: '删除'}],
    }
  },
  computed: {
    tableActionsFlow () {
      return [{key: 'up', label: '上调'}, {key: 'down', label: '下调'}, {key: 'del', label: '删除'}]
    },
    showTypeList () {
      return this.typeList.map(v => {
        v.flowList = []
        let switchList = this.switchList.filter(v1 => v1.tag === String(v.id))
        if (switchList.length > 0) {
          v.flowList.push(switchList[0].statusKey)
          v.flowList.push(switchList[0].newStatusKey)
          switchList = switchList.slice(1)
          let i = 0
          while (i < switchList.length) {
            let switchItem = switchList[i]
            if (v.flowList[v.flowList.length - 1] === switchItem.statusKey) {
              v.flowList.push(switchItem.newStatusKey)
              switchList = switchList.slice(0, i).concat(switchList.slice(i + 1))
              i = 0
            } else if (v.flowList[0] === switchItem.newStatusKey) {
              v.flowList = [switchItem.statusKey].concat(v.flowList)
              switchList = switchList.slice(0, i).concat(switchList.slice(i + 1))
              i = 0
            } else {
              i += 1
            }
          }
        }
        v.flowList = v.flowList.filter(v3 => v3 !== 'end')
        v.flowListText = v.flowList.map(v1 => this.statusList.find(v2 => v2.statusKey == v1).label.replace('中', '')).join(',')
        return v
      })
    },
    flowColumns () {
      return [{
        field: 'i',
        title: '顺序'
      },
      {
        field: 'label',
        title: '步骤'
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 100,
        fixed: 'right',
        search: false,
        export: false
      }]
    },
    columns () {
      return [{
        field: 'label',
        title: '流程名称'
      },
      {
        field: 'config',
        title: '规则'
      },
      {
        field: 'flowListText',
        title: '审批流程'
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 100,
        fixed: 'right',
        search: false,
        export: false
      }]
    },
    formParmsFlow () {
      return [{
        type: 'select',
        label: '步骤',
        key: 'statusKey',
        selectDatas: this.statusList.filter(v => !['draw', 'invalid', 'end', 'wait_1'].includes(v.statusKey)).map(v => {
          return {
            key: v.statusKey,
            label: v.label.replace('中', '')
          }
        }),
        check: {
          required: true
        }
      }]
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '流程名称',
          key: 'label',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '规则',
          key: 'config',
          check: {
            required: true
          }
        }]
        return data
      }
    }
  },
  created () {
    this.loadType()
    this.loadFlow()
    this.loadRole()
  },
  methods: {
    async loadRole () {
      let data = await roleRequest.get()
      this.personnelRoleId = (data.find(v => v.code === 'rs') || {id: null}).id
    },
    async loadType () {
      this.typeList = await askLeaveApplyRequest.getType()
    },
    async loadFlow (reload) {
      let data = null
      if (reload) {
        data = await statusManageRequest.reloadGroup()
      } else {
        data = await statusManageRequest.getGroup()
      }
      this.statusGroup = data.askLeaveApply
      this.statusList = this.statusGroup.statusList
      this.switchList = this.statusGroup.statusSwitchList.filter(v => v.tag && v.isHost && v.statusKey !== 'wait_1')
    },
    async tableActionFlow (parm) {
      let data = parm.data
      if (parm.action === 'del') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该步骤吗?'})
        if (result) {
          this.flowList = this.flowList.filter(v => v.i !== data.i)
        }
      } else if (parm.action === 'up') {
        if (data.i !== 1) {
          this.flowList[data.i - 1] = this.flowList[data.i - 2]
          this.flowList[data.i - 2] = data
        }
      } else if (parm.action === 'down') {
        if (data.i !== this.flowList.length) {
          this.flowList[data.i - 1] = this.flowList[data.i]
          this.flowList[data.i] = data
        }
      }
      this.flowList = this.flowList.map((v, index) => {
        v.i = index + 1
        return v
      })
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'del') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该假条审批流程吗?'})
        if (result) {
          await askLeaveApplyRequest.delType(this.chooseData.id)
          this.loadType()
        }
      } else if (parm.action === 'edit') {
        this.openDialog = true
      } else if (parm.action === 'flow') {
        this.flowList = this.chooseData.flowList.map((v1, index) => {
          let data = this.statusList.find(v2 => v2.statusKey == v1)
          return {
            i: index + 1,
            id: data.id,
            statusKey: data.statusKey,
            label: data.label.replace('中', '')
          }
        })
        this.modal = true
      }
    },
    async formSubmitFlow (d, resolve) {
      let data = this.statusList.find(v2 => v2.statusKey == d.statusKey)
      this.flowList.push({
        i: null,
        id: data.id,
        statusKey: data.statusKey,
        label: data.label.replace('中', '')
      })
      this.flowList.forEach((v, index) => v.i = index + 1)
      this.openDialogFlow = false
      resolve ()
    },
    async save () {
      this.loading = true
      try {
        await askLeaveApplyRequest.updateTypeFlow(this.chooseData.id, {
          flowStatusIds: this.flowList.map(v => v.id).join(','),
          personnelRoleId: this.personnelRoleId
        })
        this.modal = false
        this.loadFlow(true)
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async formSubmit (data, resolve) {
      if (data.id) {
        await askLeaveApplyRequest.updateType(data.id, data)
      } else {
        await askLeaveApplyRequest.addType(data)
      }
      this.openDialog = false
      this.loadType()
      resolve()
    }
  }
}
</script>

<style scoped lang="less">
.ask-leave-switch-config-p {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #fff;
  border-radius: 0.3rem;
}
.c-content {
  width: 100%;
  height: calc(100% - 48px);
}
.f-b {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>